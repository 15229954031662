import { action, makeAutoObservable } from 'mobx'

import { extendConsole } from '../../../shared/logging/console'
import type { ThemeMode } from '../../utils/theme'

const logger = extendConsole('ui')

export const uiStore = makeAutoObservable(
    {
        mode: undefined as ThemeMode | undefined,

        setMode(mode: ThemeMode) {
            const currentMode = this.mode

            if (mode === currentMode) {
                logger.debug('mode already set to', mode)
                return
            }

            logger.diff('mode change', currentMode, mode)
            this.mode = mode
            document.body.setAttribute('mode', mode)
        },
    },
    { setMode: action }
)
