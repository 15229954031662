import classNames from 'classnames'
import type {
    AriaAttributes,
    FunctionComponent,
    PropsWithChildren,
} from 'react'

import { useAnchorRef } from '../../../lib/hooks/useAnchorRef'
import { ariaAttributes } from '../../../lib/utils/aria'
import type { WithTestId } from '../../../lib/utils/testid'
import type { BrandColor } from '../../../types/color'
import { Container } from '../grid'
import styles from './section.module.scss'

/** Basic page building block.
 * Can display a visual with responsive configuration.
 * Use this inside a bare layout for easy page creation. */

export const Section: FunctionComponent<PropsWithChildren<SectionProps>> = ({
    tag: Tag = 'section',
    'data-testid': testId,
    background = 'theme-background',
    loading,
    grid,
    anchor,
    className,
    children,
    role,
    padHorizontal = true,
    ...restOfProps
}) => {
    const anchorRef = useAnchorRef(anchor)

    return (
        <Tag
            {...ariaAttributes(restOfProps)}
            id={anchor}
            role={role}
            ref={anchorRef}
            data-testid={testId}
            className={classNames(
                className,
                styles.section,
                styles[`background-${background}`],
                {
                    [styles['scroll-anchor']]: anchor,
                    [styles.loading]: loading,
                }
            )}
        >
            <Container>
                <div
                    className={classNames({
                        [styles.grid]: grid,
                        [styles['pad-horizontal']]: padHorizontal,
                    })}
                >
                    {children}
                </div>
            </Container>
        </Tag>
    )
}

export type SectionProps = WithTestId<
    AriaAttributes & {
        /** WAI-ARIA role */
        role?: string
        /** Determines if this the main section on page, affects styling. */
        preset?: 'primary' | 'default'
        /** This color will be stretched to full container width. */
        background?: BrandColor | 'transparent'
        /** Are we in the process of loading some content inside? */
        loading?: boolean
        /** Render two-column grid layout inside. */
        grid?: boolean
        /** Link to this section within a page. */
        anchor?: string
        /** Custom HTML tag to render. */
        tag?: 'section' | 'div'
        /** Extra classname for extra special cases. */
        className?: string
        /** Padding on the left and right. */
        padHorizontal?: boolean
    }
>
