import type { PageSection } from '../lib/static/page'
import { withTheme } from '../lib/utils/withTheme'
import { Announcement } from './announcement'
import { BackgroundImageSection } from './background-image-section'
import { Banner } from './banner'
import { BentoBox } from './bento-box'
import { Block } from './block'
import { ComparisonTable } from './comparison-table'
import { CreditMattersTable } from './credit-matters-table'
import { CTASection } from './cta-section'
import { Embedded } from './embedded'
import { EmbeddedTypeform } from './embedded-typeform'
import { FAQ } from './faq'
import { FeatureHero } from './feature-hero'
import { FeatureSectionThreeColumn } from './feature-section-three-column'
import { FeatureSectionV2 } from './feature-section-v2'
import { FormSection } from './form-section'
import { FullWidthVideo } from './full-width-video'
import { Hero } from './hero'
import { HeroV2 } from './hero-v2'
import { HeroV2Carousel } from './hero-v2-carousel'
import { HomepageHero } from './homepage-hero'
import { HorizontalScroller } from './horizontal-scroller'
import { Marquee } from './marquee'
import { MultiCopySection } from './multi-copy-section'
import { MultiImageSection } from './multi-image-section'
import { NineBlockGrid } from './nine-block-grid'
import { PhoneScroller } from './phone-scroller'
import { ReviewCarousel } from './review-carousel'
import { SectionOfContent } from './section-of-content'
import { SectionOfFaQs } from './section-of-faqs'
import { SectionOfInvestors } from './section-of-investors'
import { SectionOfPressBanners } from './section-of-press-banners'
import { SectionOfSteps } from './section-of-steps'
import { SectionOfThumbnails } from './section-of-thumbnails'
import { SideBySide } from './side-by-side'
import { SimpleHeaderSection } from './simple-header-section'
import { VideoSection } from './video'

export type SectionRegistry = Record<string, React.ElementType>

/**
 * Default pool of static page sections.
 *
 * Define page section "type" as the key, and pass a React component as a value.
 */
export const SECTIONS_BY_TYPE: SectionRegistry = {
    // integrated components
    HomepageHero,
    Hero,
    HeroV2,
    HeroV2Carousel,
    BentoBox,
    Block,
    SimpleHeaderSection,
    CreditMattersTable,
    NineBlockGrid,
    FeatureHero,
    FeatureSectionV2,
    FeatureSectionThreeColumn,
    CtaSection: CTASection,
    SectionOfThumbnails, // backwards compatible (old content now uses new component)
    SectionOfFaQs, // backwards compatible (old content now uses new component)
    SectionOfReviews: ReviewCarousel, // backwards compatible (old content now uses new component)
    PhoneScroller,
    BackgroundImageSection,
    FullWidthVideo,
    SideBySide,
    MultiCopySection,
    Marquee,
    HorizontalScroller,
    MultiImageSection,
    FAQ,
    Section: SectionOfContent,
    Steps: SectionOfSteps,
    SectionOfPressBanners,
    Announcement,
    SectionOfInvestors,
    Video: VideoSection,
    Embedded,
    EmbeddedTypeform,
    FormSection,
    Banner,
    ComparisonTable,
}

/**
 * Maps static page sections to their appropriate component. By default, pulls
 * from the {@link SECTIONS_BY_TYPE} registry, but a custom registry can be
 * passed to the "registry" prop.
 */
export function SectionResolver({ sections, registry }: SectionResolverProps) {
    return <>{sections.map((s, i) => renderSection(s, registry, i))}</>
}

/**
 * Renders the appropriate section component from static page data based on the "type".
 */
export function renderSection(
    section: PageSection,
    registry: SectionRegistry = SECTIONS_BY_TYPE,
    index = 0
): JSX.Element | null {
    const { sys, theme, type } = section
    const SectionComponent = registry[type]

    if (!SectionComponent) {
        console.warn(
            `A section of type "${type}" was found, but is undefined in the registry.`
        )
        return null
    }

    const sectionContent = (
        <section key={sys.id}>
            <SectionComponent
                {...section}
                headingTag={index === 0 ? 'h1' : undefined}
            />
        </section>
    )

    const _theme = typeof theme === 'object' ? theme?.key : theme

    return withTheme(_theme, sectionContent)
}

interface SectionResolverProps {
    sections: PageSection[]
    registry?: SectionRegistry
    index?: number
}
